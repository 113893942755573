<template>
  <div class="about">
    <banner-hero :bannerHero="bannerHero" />
    <service-bullets />
  </div>
</template>
<script>
import BannerHero from '@/components/BannerHero';
import ServiceBullets from '@/components/Services/ServiceBullets';

export default {
  name: 'Services',
  components: { ServiceBullets, BannerHero },
  data() {
    return {
      bannerHero: BannerHero.methods.Object(
        'Servicios',
        null,
        'https://i.imgur.com/wNe7d73.jpeg',
        ' ',
        'Podemos asesorarle desde el punto de vista jurídico y con la experiencia adquirida, ' +
          'si los productos o servicios que pretende comercializar se encuentra disponible para ' +
          'obtener derechos, o bien informarle los riesgos que implica el uso de elementos previamente ' +
          'protegidos por terceros, ahorrando tiempo y dinero. ' +
          '<br>' +
          '<br>' +
          'Nuestros servicios consisten en registro de marcas, renovación, así como en los procedimientos efectuados por el IMPI, para verificar los derechos protegidos por marcas registradas y tramites ante INDAUTOR para proteger toda clase de derechos de autor.' +
          '<br>' +
          '<br>' +
          'Asesoría jurídica para obtener el registro de marcas, avisos comerciales, reservas de dominio. Asesoría jurídica para obtener una marca una vez que la solicitud ha sido negada por parte del IMPI'
      ),
    };
  },
};
</script>
