<template>
  <div :class="$style.component">
    <!-- Start Service -->
    <section class="container overflow-hidden py-5 col-10 col-md-8">
      <div class="row mb-3">
        <div class="service-header col-1 text-end light-300">
          <i class="bx bx-folder-open h3 mt-1"></i>
        </div>
        <div class="service-heading col-10 col-lg-9 text-start float-end light-300">
          <h2 class="h3 pb-4 typo-space-line">Te ofrecemos lo siguiente:</h2>
        </div>
      </div>
      <div class="row gx-5 gx-sm-3 gx-lg-5 gy-lg-5 gy-3 pb-3 projects">
        <!-- Start Recent Work -->
        <!-- ui branding are the selectors-->
        <div
          class="col-xl-4 col-md-6 col-sm-6 project ui branding"
          v-for="(serviceCard, index) in serviceCards"
          :key="index"
        >
          <span class="btn rounded-pill mb-lg-3 px-lg-4 bg-primary text-white disabled" aria-disabled="true">{{
            serviceCard.name
          }}</span>
          <ul class="card-text">
            <li v-for="(items, index) in serviceCard.items" :key="index">{{ items.description }}</li>
          </ul>
        </div>
        <!-- End Recent Work -->
      </div>
    </section>
    <!-- End Service -->
  </div>
</template>

<script>
export default {
  name: 'ServiceBullets',
  data() {
    return {
      serviceCards: [
        {
          name: 'Registro de marcas y otros signos distintivos',
          items: [
            {
              description: 'Registro de Marcas por 10 años',
            },
            {
              description: 'Renovación de registro de marcas',
            },
            {
              description: 'Registro de Nombre y Avisos comerciales',
            },
          ],
        },
        {
          name: 'Propiedad industrial',
          items: [
            {
              description: 'Registro de Patentes',
            },
            {
              description: 'Protección de Secretos industriales',
            },
            {
              description: 'Modelos y diseños industriales',
            },
          ],
        },
        {
          name: 'Derechos de autor',
          items: [
            {
              description: 'Registro de obras',
            },
            {
              description: 'Reservas de dominio',
            },
          ],
        },
        {
          name: 'Contratos',
          items: [
            {
              description: 'Elaboración y revisión de Contratos de Cesión de Derechos de marcas',
            },
            {
              description: 'Elaboración y revisión de Contratos de licencias y franquicias',
            },
            {
              description: 'Elaboración y revisión de Contratos de privacidad y confidencialidad',
            },
          ],
        },
        {
          name: 'Materia contenciosa y litigios',
          items: [
            {
              description: 'Iniciar Procedimientos administrativos de nulidad, caducidad e infracción',
            },
            {
              description: 'Contestar requerimientos de autoridades en materia de Propiedad Intelectual',
            },
            {
              description: 'Demandas para reclamo de daños y perjuicios por uso ilegal',
            },
          ],
        },
        {
          name: 'Diseño grafico',
          items: [
            {
              description: 'Diseño de logos realizado por especialista en diseño gráfico',
            },
          ],
        },
        {
          name: 'Dominios',
          items: [
            {
              description: 'Registro de dominios',
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" module>
.component :global {
  .btn:disabled,
  .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 1;
  }
}
</style>
